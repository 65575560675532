
import AppButton from '@/components/ui/AppButton.vue';
import { FileProcessedErrorType, FileUploadStatusEnum } from '@/enums';
import { AllLabels, FileUploadStatus } from '@/interfaces';
import { labelsStore } from '@/store';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    ProcessingProgressTableStatus,
    AppButton
  }
})
export default class ProcessingProgressTableStatus extends Vue {

  @Prop({ required: true })
  public fileUploadStatus!: FileUploadStatus;

  @Prop({ default: false })
  public showRetry!: boolean;

  @Emit()
  public retry(): void {
  }

  @Emit()
  public remove(): void {
  }

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public get notStarted(): boolean {
    return this.fileUploadStatus.status === FileUploadStatusEnum.notStarted;
  }

  public get processing(): boolean {
    return this.fileUploadStatus.status === FileUploadStatusEnum.inProgress;
  }

  public get isDone(): boolean {
    return this.fileUploadStatus.status === FileUploadStatusEnum.complete;
  }

  public get hasError(): boolean {
    return !!this.errorString;
  }

  public get errorString(): string {
    if (this.fileUploadStatus.status !== FileUploadStatusEnum.error) {
      return '';
    }
    switch (this.fileUploadStatus.errorType) {
      case FileProcessedErrorType.MissingInitialFile:
        return 'Missing Initial File';
      case FileProcessedErrorType.InvalidFileType:
        return 'Invalid File Type';
      case FileProcessedErrorType.InvalidFile:
        return 'Invalid File';
      case FileProcessedErrorType.Unknown:
        return 'Unknown';
      default:
        return 'Unknown';
    }
  }
}

