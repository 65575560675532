
import { AllLabels } from '@/interfaces';
import { labelsStore } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import Footer from '@/components/common/Footer.vue';
import ResultsReadyPageContent from './ResultsReadyPageContent.vue';


@Component({
  components: {
    Footer,
    ResultsReadyPageContent
  }
})
export default class ResultsReadyPage extends Vue {

  public get labels(): AllLabels {
    return labelsStore.labels;
  }
}

