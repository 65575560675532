
import { AllLabels } from '@/interfaces';
import { labelsStore } from '@/store';
import { Component, Vue } from 'vue-property-decorator';


@Component({
  components: {
  }
})
export default class DemoOffering extends Vue {

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public onDemoClicked(): void {
    this.$router.push({name: 'Demo'});
  }
}

