
import { AllLabels, FileUploadStatus } from '@/interfaces';
import { labelsStore, uploadStore } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import ProductTitle from '@/components/common/ProductTitle.vue';
import Footer from '@/components/common/Footer.vue';
import FileUpload from './FileUpload/FileUpload.vue';
import DemoOffering from './DemoOffering/DemoOffering.vue';
import ProcessingPageContent from '../ProcessingPage/ProcessingPageContent.vue';
import Captcha from '@/components/ui/Captcha.vue';

@Component({
  components: {
    Captcha,
    ProductTitle,
    FileUpload,
    DemoOffering,
    Footer,
    ProcessingPageContent
  }
})
export default class UploadPage extends Vue {

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public get fileUploadStatuses(): FileUploadStatus[] {
    return uploadStore.files;
  }
}

