
import { AllLabels, CaseData } from '@/interfaces';
import { labelsStore, uploadStore } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import SelectCasePageTable from './SelectCasePageTable.vue';
import AppButton from '@/components/ui/AppButton.vue';

@Component({
  components: {
    SelectCasePageTable,
    AppButton
  }
})
export default class ProcessingPageContent extends Vue {

  public get selectedCase(): CaseData | null {
    return uploadStore.selectedCase;
  }

  public get cases(): CaseData[] {
    return uploadStore.cases;
  }

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public get canContinue(): boolean {
    return !!this.selectedCase;
  }

  public onAddMoreFilesClicked(): void {
    uploadStore.showUploadPage();
  }

  public onCaseSelected(caseData: CaseData): void {
    uploadStore.selectCase(caseData);
  }

  public onContinueClicked(): void {
    uploadStore.showResultsReadyPage();
  }
}

