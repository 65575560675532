
import { AllLabels, CaseData } from '@/interfaces';
import { labelsStore } from '@/store';
import { formatDuration } from '@/utils';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class SelectCasePageTable extends Vue {

  @Prop({ required: true })
  public cases!: CaseData[];

  @Prop()
  public value!: CaseData | null;

  @Emit()
  public input(option: CaseData) {
    return option;
  }

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public formatDuration(caseDurationMs: number): string {
    return formatDuration(caseDurationMs);
  }

  public select(caseData: CaseData): void {
    this.input(caseData);
  }
}

