
import { captchaService } from '@/services/CaptchaService';
import { Component, Vue } from 'vue-property-decorator';

declare var grecaptcha: any;

@Component({})
export default class Captcha extends Vue {
  private _id: number = 0;
  // captcha is disabled
  // private _rendered: boolean = false;
  private _waitingResolveReject?: { resolve: (data: string) => void, reject: (error: any) => void };

  public mounted(): void {
    (window as any).onRecaptchaLoadCallback = () => {
      this._tryRender();
    }
    this._tryRender();
    captchaService.register(this);
  }

  private _tryRender(): void {
    // captcha is disabled
    /*if (!this._rendered && grecaptcha && grecaptcha.render) {
      this._id = grecaptcha.render(
        this.$el as HTMLElement,
        {
          sitekey: '6LdQPYUpAAAAAPXIA4G0Ocp-Sb81WY9oeSPztzWr',
          theme: 'light',
          callback: (token: string) => {
            if (this._waitingResolveReject) {
              this._waitingResolveReject.resolve(token);
              this._waitingResolveReject = undefined;
            }
          },
          "error-callback": () => {
            if (this._waitingResolveReject) {
              this._waitingResolveReject.reject('error');
              this._waitingResolveReject = undefined;
            }
          },
          'expired-callback': () => {
            if (this._waitingResolveReject) {
              this._waitingResolveReject.reject('expired');
              this._waitingResolveReject = undefined;
            }
          },
          tabindex: 2,
        }
      );
      this._rendered = true;
    }*/
  }

  public async execute(): Promise<string> {
    if (this._waitingResolveReject) {
      throw new Error('captcha already in progress');
    }
    const existingToken = grecaptcha.getResponse(this._id);
    if (existingToken) {
      return existingToken;
    }
    const promise = new Promise<string>((resolve, reject) => {
      this._waitingResolveReject = { resolve, reject };
    });
    grecaptcha.execute(this._id);
    const result = await promise;
    return result;
  }
}
