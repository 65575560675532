
import { AllLabels } from '@/interfaces';
import { labelsStore, uploadStore } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import UploadPage from './UploadPage/UploadPage.vue';
import ProcessingPage from './ProcessingPage/ProcessingPage.vue';
import SelectCasePage from './SelectCasePage/SelectCasePage.vue';
import ResultsReadyPage from './ResultsReadyPage/ResultsReadyPage.vue';
import ResultsPage from './ResultsPage/ResultsPage.vue';


@Component({
  components: {
    UploadPage,
    ProcessingPage,
    SelectCasePage,
    ResultsReadyPage,
    ResultsPage
  }
})
export default class HomePage extends Vue {

  public get showUpload(): boolean {
    return uploadStore.showUpload;
  }

  public get showProcessing(): boolean {
    return uploadStore.showProcessing;
  }

  public get showSelectCase(): boolean {
    return uploadStore.showSelectCase;
  }

  public get showResultsReady(): boolean {
    return uploadStore.showResultsReady;
  }

  public get showResults(): boolean {
    return uploadStore.showResults;
  }

  public get labels(): AllLabels {
    return labelsStore.labels;
  }
}

