
import { AllLabels, FileUploadStatus } from '@/interfaces';
import { labelsStore } from '@/store';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import ProcessingProgressTableStatus from './ProcessingProgressTableStatus.vue';

@Component({
  components: {
    ProcessingProgressTableStatus
  }
})
export default class ProcessingProgressTable extends Vue {

  @Prop({ required: true })
  public fileUploadStatuses!: FileUploadStatus[];

  @Emit()
  public retry(status: FileUploadStatus): FileUploadStatus {
    return status;
  }

  @Emit()
  public remove(status: FileUploadStatus): FileUploadStatus {
    return status;
  }

  public get labels(): AllLabels {
    return labelsStore.labels;
  }
}

