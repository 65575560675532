
import { AllLabels } from '@/interfaces';
import { labelsStore, uploadStore } from '@/store';
import { Component, Ref, Vue } from 'vue-property-decorator';
import AppButton from '@/components/ui/AppButton.vue';
import { uploadService } from '@/services';

@Component({
  components: {
    AppButton
  }
})
export default class FileUpload extends Vue {
  public dragging: boolean = false;

  @Ref('fileInput')
  private fileInput: HTMLInputElement | undefined;

  public get labels(): AllLabels {
    return labelsStore.labels;
  }
  
  public onDragOver(e: any): void {
    e.preventDefault();
    this.dragging = true;
  }

  public onDragLeave(e: any): void {
    const targetClass: string = e.toElement.className;
    const fileUploadContentIndex = targetClass.indexOf('file-upload-content');
    if (fileUploadContentIndex === -1) {
      this.dragging = false;
    }
  }
  
  public onDrop(e: any): void {
    e.preventDefault();
    this.handleFileList(e.dataTransfer.files);
    this.dragging = false;
  }

  public onBrowseClicked(): void {
    this.$el.getElementsByTagName('input')[0].click();
  }

  public get validFileTypes(): string {
    return '.csv, .tg2, .dat';
  }

  public get fileUploadHint(): string {
    return 'The following systems are are supported:\nThermogard XP (.csv file)\nThermogard HQ (.tg2 file)\nBrainCool \\ IQool (.dat file)';
  }

  public async handleFileList(fileList: FileList): Promise<void> {
    const files: File[] = [];
    for (let i = 0; i < fileList.length; i++) {
      files.push(fileList[i]);
    }
    uploadStore.addFiles(files);
    // uploadStore.showProcessingPage();
    await uploadService.uploadFiles();
    if (this.fileInput) {
      this.fileInput.value = '';
    }
  }

  public browseFilesChange(event: any): void {
    const fileList: FileList | undefined  = event?.target?.files;
    if (!fileList) {
      return;
    }
    this.handleFileList(fileList);
  }
}

