
import { AllLabels, FileUploadStatus } from '@/interfaces';
import { labelsStore, uploadStore } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import ProcessingProgressTable from './ProcessingProgressTable.vue';
import { FileUploadStatusEnum } from '@/enums';
import { uploadService } from '@/services';
import AppButton from '@/components/ui/AppButton.vue';
import { captchaService } from '@/services/CaptchaService';

@Component({
  components: {
    ProcessingProgressTable,
    AppButton
  }
})
export default class ProcessingPageContent extends Vue {

  public get progressColor(): string {
    return this.hasError ? 'red' : 'blue';
  }

  public get percentComplete(): number {
    let totalCount = this.fileUploadStatuses.length;
    if (totalCount === 0) {
      return 0;
    }
    let completeCount = 0;
    this.fileUploadStatuses.forEach((fileUploadStatus) => {
      if (fileUploadStatus.status === FileUploadStatusEnum.complete || fileUploadStatus.status === FileUploadStatusEnum.error) {
        completeCount++;
      }
    });

    return completeCount / totalCount * 100;
  }

  public get fileUploadStatuses(): FileUploadStatus[] {
    return uploadStore.files;
  }

  public get hasError(): boolean {
    return this.fileUploadStatuses.some((fileUploadStatus) => {
      return fileUploadStatus.status === FileUploadStatusEnum.error;
    });
  }

  public async retry(status: FileUploadStatus): Promise<void> {
    const captchaToken = await captchaService.checkCaptcha();
    await uploadService.tryUploadFile(status, captchaToken);
  }

  public remove(status: FileUploadStatus): void {
    uploadService.remove(status);
  }

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public get canContinue(): boolean {
    const anyComplete = this.fileUploadStatuses.some((fileUploadStatus) => {
      return fileUploadStatus.status === FileUploadStatusEnum.complete;
    });
    const nonePending = this.fileUploadStatuses.every((fileUploadStatus) => {
      return fileUploadStatus.status === FileUploadStatusEnum.complete ||
        fileUploadStatus.status === FileUploadStatusEnum.error;
    });
    return anyComplete && nonePending;
  }

  public onContinueClicked(): void {
    uploadService.analyzeFiles();
  }
}

