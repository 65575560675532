
import { AllLabels } from '@/interfaces';
import { labelsStore, settingsStore } from '@/store';
import { DateWrapper } from '@/utils';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
})
export default class DateTimeInputWrapper extends Vue {

  public showDialog: boolean = false;
  private _currentText: string = '';

  public date!: string;
  public time!: string;

  @Prop({ default: ''})
  public error!: string;

  @Prop()
  public value!: string;

  @Prop({ default: false })
  public disabled!: boolean;

  @Prop({ default: '' })
  public suffix!: string;

  @Prop({ default: false })
  public required!: boolean;

  @Prop({ default: '' })
  public label!: string;

  @Prop({ default: false })
  public dateOnly!: boolean;

  @Prop({ default: '' })
  public defaultValue!: string;

  @Prop({ default: '' })
  public hint!: string;

  @Emit()
  public input(dateTime: string) {
    return dateTime;
  }

  @Emit()
  public errorMessage(message: string) {
    return message;
  }

  @Watch('error', { immediate: true })
  public onErrorChange(): void {
    this._checkErrorMessage();
  }

  @Watch('value', { immediate: true })
  public onValueChange(): void {
    let date = new DateWrapper(this.value);
    if (!date.canDisplay()) {
      date = new DateWrapper(this.defaultValue);
    }
    if (!date.canDisplay()) {
      date = new DateWrapper();
    }

    this.date = date.toDisplayDate();
    this.time = date.toDisplayTime(false);
  }

  public get maxWidth(): string {
    return this.dateOnly ? '400px' : '800px';
  }

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public get locale(): string {
    return labelsStore.language;
  }

  public get timePickerFormat(): string {
    return settingsStore.use12HourClock ? 'ampm' : '24hr';
  }

  public onTextChanged(newText: string): void {
    this._currentText = newText;
    let isValid: boolean = true;
    this.allRules.forEach((rule) => {
      const ruleResult = rule(newText);
      if (typeof(ruleResult) === 'string') {
        isValid = false;
      }
    });
    if (isValid) {
      this.input(newText);
    }
    this._checkErrorMessage();
  }

  public setShowDialog(showDialog: boolean): void {
    this.showDialog = showDialog;
  }

  public dateUpdated(date: string): void {
    this.date = date;
    // forceUpdate is required for the date-picker to update
    this.$forceUpdate();
  }

  public dateTimePicked(): void {
    this.setShowDialog(false);
    const newValue = `${this.date} ${this.time}`;
    this.input(newValue);
  }

  public get allRules(): ((text: string) => string | true)[] {
    const result = [];
    if (this.required) {
      result.push(this.requiredRule);
    }
    result.push(this.formatRule);
    return result;
  }

  public get formatRule(): (text: string) => string | true {
    const use12HourClock = settingsStore.use12HourClock;
    return (text: string) => {
      text = text && text.trim();
      if (text) {
        // has input
        const isDatetime = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(text);
        const isDatetimeWithAmPm = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2} (AM|PM)$/.test(text);
        if (use12HourClock && !isDatetimeWithAmPm) {
          return 'Must be in 2022-10-31 02:44 AM format';
        } else if (!use12HourClock && !isDatetime) {
          return 'Must be in 2022-10-31 14:44 format';
        } else {
          const date = new DateWrapper(text);
          if (date.isNaN()) {
            return 'Invalid date';
          }
        }
      }
      return true;
    }
  }

  public get requiredRule(): (text: string) => string | true {
    return (text: string) => {
      if (!text || !text.trim()) {
        return 'Required';
      } else {
        return true;
      }
    };
  }

  private _checkErrorMessage(): void {
    const errorMessage = this._getErrorMessage(this._currentText);
    this.errorMessage(errorMessage);
  }

  private _getErrorMessage(text: string): string {
    for (let i = 0; i < this.allRules.length; i++) {
      const ruleResult = this.allRules[i](text);
      if (typeof(ruleResult) === 'string') {
        return ruleResult;
      }
    }
    if (this.error) {
      return this.error;
    }
    return '';
  }
}

